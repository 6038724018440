import { get, post } from "./index.js";
import ajax from "common/js/http";

// 首页数据 ok
let getHomeData = get("/index/index");

// 页面详情 ok
let getWebPage = get("/webPage/get");
// 查询订单列表 ok
let postOrderList = post("/orderQuery/list");

// 查询订单信息
let getOrderQuery = function(argument) {
  return ajax.get(`/orderQuery/getInfo?orderNum=${argument}`);
};
// get('/orderQuery/getInfo')
// 查询订单支付状态
let getOrderStatus = get("/orderQuery/getPayStatus");

// 客服信息 ok
let getServiceData = get("/customerServiceInfo/get");

// 地区列表 ok
let getAreaList = get("/area/list");

// 下单 ok
let postPayOrder = post("/orderPay/purchase");
// 吸底按钮下单
let postBottomPayOrder = post("/orderPay/purchase/xidi");
// 二次支付 ok
let postAgainPayOrder = function(argument) {
  return ajax.post(`/orderPay/payAgain?orderNo=${argument}`);
};
// post('/orderPay/payAgain')
// 获取验证码
let getNumberCode = function(argument) {
  return ajax.get(`/orderPay/sendVerificationCode?mobileNumber=${argument}`);
};

// 滚动订单信息
let getRolling = function() {
  return ajax.get("/orderQuery/rolling");
};

// 更新身份证号（收集）
let updateIdCard = post("/orderPay/update/IdCard/info");

// 获取微信code
const getCode = get("/auth/getCode");

// 获取微信openId
const getUserInfo = get("/auth/getUserInfo");

const getCustomerServiceInfo = function (argument) {
  return ajax.get(`/customerServiceInfo/get?userId=${argument}`)
}

export {
  getHomeData,
  getWebPage,
  postOrderList,
  getOrderQuery,
  getOrderStatus,
  getServiceData,
  getAreaList,
  postPayOrder,
  postAgainPayOrder,
  getNumberCode,
  postBottomPayOrder,
  getRolling,
  updateIdCard,
  getCode,
  getUserInfo,
  getCustomerServiceInfo
};
