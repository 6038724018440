import axios from 'axios'
import router from 'src/router/index.js'
import store from 'src/store/index'

import { errorCode } from 'common/js/config'

const instance = axios.create({ timeout: 5000 })

// instance.defaults.baseURL = 'http://192.168.100.100:8881/seller'
instance.defaults.baseURL = 'https://m-shop.rzcf.com/seller/'
// instance.defaults.baseURL = 'http://58.58.178.121:10001/seller/'
// instance.defaults.baseURL = '/seller'
// instance.defaults.baseURL = 'https://api-bf-seller.vyiot.com/seller'
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
instance.interceptors.request.use(
  config => {
    config.headers.clickid = store.state.clickid
    const token = ''
    token && (config.headers.Token = token)
    return config
  },
  error => {
    return Promise.reject(error)
  })

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const { code } = response.data
    if (code === errorCode.ERR_NO) {
      // 参数错误
      console.log('参数错误', response.data)
    } else if (code === errorCode.ERR_BUSY) {
      // 服务器繁忙
      console.log('服务器繁忙', response.data)
    } else if (code === 404) {
      router.push({
        name: "404",
      });
    } else {
      return response.data
    }
  },
  error => {
    const { response } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      // errorHandle(response.data.code, response.data)
      return Promise.reject(response)
    }
  })

export default instance
