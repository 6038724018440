import ajax from '../common/js/http.js'
import router from '../router'

export function post(url) {
  return function (params) {
    return ajax.post(url, params).then((result) => {
      if (result) {
        return result
      }
    })
  }
}

export function get(url) {
  return function (params = {}) {
    return ajax.get(url, { params }).then((result) => {
      if (result) {
        return result
      } else {
        const date = new Date();
        const timestamp = date.getTime();
        if (timestamp >= 1641225600000 && timestamp <= 1641247200000) {	// 维护时间
        // if (timestamp >= 1641180600000 && timestamp <= 1641202200000) {	// 测试时间
          router.replace({
            path: '/notice'
          });
        }
        return new Promise();
      }
    })
  }
}

export function put(url) {
  return function (params) {
    return ajax.put(url, params).then((result) => {
      if (result) {
        return result
      }
    })
  }
}

export function del(url) {
  return function (params) {
    const options = {
      data: params
    }
    return ajax.delete(url, options).then((result) => {
      if (result) {
        return result
      }
    })
  }
}